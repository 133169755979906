/**
* @Author: Ajay
* @Date:   24-Feb-2017 12:40 +05:30
* @Project: Ezyprocure
* @Filename: supplier.navbar.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 11-Dec-2017 15:30 +05:30
*/



(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('SupplierNavbarController', SupplierNavbarController);

  SupplierNavbarController.$inject = ['$rootScope', '$window', '$stateParams', '$timeout', '$state', '$mdDialog', 'XBorderFlyerService', 'ImageUploadService', 'ReleaseNotesMasterService', 'WhatsNewService', 'toastr', 'Auth', 'SupplierAccountService', 'SupplierDataService', '$localStorage', 'UserRedirectService', 'NotificationService', 'API_CONSTANTS', 'Principal', 'AuthServerProvider', '$cookies', 'GLOBAL_CONSTANTS'];

  function SupplierNavbarController($rootScope, $window, $stateParams, $timeout, $state, $mdDialog, XBorderFlyerService, ImageUploadService, ReleaseNotesMasterService, WhatsNewService, toastr, Auth, SupplierAccountService, SupplierDataService, $localStorage, UserRedirectService, NotificationService, API_CONSTANTS, Principal, AuthServerProvider, $cookies, GLOBAL_CONSTANTS) {
    var vm = this;

    vm.getSupplierProfile = getSupplierProfile;
    vm.getEzysourcingTempToken = getEzysourcingTempToken;
    $rootScope.back = back;
    vm.userInfo = JSON.parse($localStorage["supplier-profile-info"] || "{}");
    vm.redirectToEzypayment = redirectToEzypayment;
    vm.redirectToEzyap = redirectToEzyap;
    vm.redirectToEzyInventory = redirectToEzyInventory;
    vm.redirectToAlexWorld = redirectToAlexWorld;
    vm.onInit = onInit;
    vm.userRole = null;
    vm.submitRequest = submitRequest;
    vm.checkPlan = checkPlan;
    vm.hasCbPlatform = hasCbPlatform;
    vm.authenticateAndRedirect = authenticateAndRedirect;
    vm.requestOTP = requestOTP;
    // 2FA changes
    vm.otp = undefined;
    vm.isOtpValid = true;
    vm.counterMilliSeconds = undefined;
    vm.counterSeconds = undefined;
    vm.counterMinutes = undefined;
    vm.validateOTP = validateOTP;
    vm.resendOTP = resendOTP;
    // whats new dialog
    vm.whatsNew = {};
    vm.hideDialog = hideDialog;
    vm.cancelDialog = cancelDialog;
    // xborder flyer
    vm.xborderFlyerUrl = GLOBAL_CONSTANTS.CROSS_BORDER_FLYER_URL;
    vm.xborderTermsAndConditions = GLOBAL_CONSTANTS.CROSS_BORDER_TERMS_AND_CONDITIONS_URL;
    vm.skipCount = 0;
    vm.supplierName = "";
    vm.skipXborderSignIn = skipXborderSignIn;
    vm.rejectXborderSignIn = rejectXborderSignIn;
    vm.acceptXborderSignIn = acceptXborderSignIn;
    vm.showTermsAndConditions = showTermsAndConditions;
    //promo flyer
    vm.promoPopupUrl = GLOBAL_CONSTANTS.PROMO_POPUP_IMAGE_URL;
    vm.promoFormUrl = GLOBAL_CONSTANTS.PROMO_POPUP_FORM_URL;
    vm.promoSkipCount = 0;
    vm.closePromoFlyer = closePromoFlyer;
    vm.continueToPromoForm = continueToPromoForm;
    vm.showSubscriptionRenewalPopup = showSubscriptionRenewalPopup;
    function onInit() {
      vm.accessForEzyinventory = SupplierDataService.getSupplierProfile().accessDTO.accessForEzyinventory;
      vm.userRole = (vm.userInfo.supplierUser ? vm.userInfo.supplierUser.userRoles.name : null);
      vm.supplierName = vm.userInfo.user.firstName + " " + (vm.userInfo.user.lastName ? vm.userInfo.user.lastName : "");
      checkWhatsNewStatus();
      /* promo popup */
      // checkPromoPopUpStatus();
      /* remove comment for xBorder flyer */
      checkFlyerStatus();
      // license expiration check
      const licenseExpirationDataString = sessionStorage.getItem('licenseExpirationData');

      if (licenseExpirationDataString) {
        console.log(licenseExpirationDataString);
        const licenseExpirationData = JSON.parse(licenseExpirationDataString);
        console.log(licenseExpirationData);
        if (Object.keys(licenseExpirationData).length > 0) {
          console.log('License expiration data is available:', licenseExpirationData);
          vm.showSubscriptionRenewalPopup(licenseExpirationDataString);
        } else {
          console.log('License expiration data is an empty object.');
        }
      } else {
        console.log('License expiration data is not available.');
      }


    }

    function checkWhatsNewStatus() {
      ReleaseNotesMasterService.getActiveReleaseNotes().then(function(response) {
        vm.whatsNew = response.data[0];
        if(Array.isArray(response.data) && WhatsNewService.getHasReadValue(response.data[0].releaseVersion) && (response.data[0].noticeFor=="FOR_SUPPLIERS"||response.data[0].noticeFor=="FOR_ALL")) {
          popWhatsNewDialog();
        }
      }, function(error) {

      });
    }

    function checkPromoPopUpStatus() {
      vm.promoSkipCount = XBorderFlyerService.getSkipCount("promo-skip-count");
      var isPopupShown = XBorderFlyerService.getPromoPopupStatus() == 'true';
      isPopupShown || showPromoPopup();
    }

    function showPromoPopup() {
      $mdDialog.show({
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        multiple: true,
        templateUrl: 'app/supplier-modules/common/promo-popup/promo-popup.tmpl.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false
      }).then(function () {

      }, function () {

      });
    }

    function closePromoFlyer() {
      XBorderFlyerService.setSkipCount(vm.promoSkipCount, "promo-skip-count");
      $mdDialog.hide();
    }

    function continueToPromoForm() {
      setPromoPopupStatus(true);
      closePromoFlyer();
      $window.open(vm.promoFormUrl);
    }

    function setPromoPopupStatus(status) {
      XBorderFlyerService.setPromoPopupStatus(status);
    }

    function checkFlyerStatus() {
      vm.skipCount = XBorderFlyerService.getSkipCount("xborder-skip-count");
      XBorderFlyerService.getOptInStatus().then(function(optInStatus) {
        if (optInStatus != "ACCEPT" && optInStatus != "REJECT")
          popXBorderFlyer();
      },
      function(error) {
        toastr.error('Internal Server Error', '500');
      });
    }

    function showTermsAndConditions(event) {
      event.preventDefault();
    }

    function acceptXborderSignIn() {
      XBorderFlyerService.generateScreenshot().then(function(base64) {
        ImageUploadService.uploadImage(base64).then(function (response) {
          var params = {
            url: response.url,
            optInStatus: "ACCEPT"
          }
          submitOptInStatus(params);
        })
      });
    }

    function submitOptInStatus(params) {
      XBorderFlyerService.updateOptInStatus(params).then(function(response) {
        params.optInStatus == "ACCEPT" ? toastr.success("Successfully completed", "Cross Border Opt In") : toastr.error("Module Rejected", "Cross Border Opt In");
        closeXborderFlyer();
      },
      function(error) {
        toastr.error('Internal Server Error', '500');
      });
    }

    function rejectXborderSignIn(event) {
      event.preventDefault();
      var params = {
        url: null,
        optInStatus: "REJECT"
      }
      submitOptInStatus(params);
    }

    function skipXborderSignIn() {
      XBorderFlyerService.setSkipCount(vm.skipCount, "xborder-skip-count");
      closeXborderFlyer();
    }

    function closeXborderFlyer() {
      $mdDialog.hide();
    }

    function popXBorderFlyer() {
      $mdDialog.show({
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        multiple: true,
        templateUrl: 'app/supplier-modules/common/xborder-flyer/xborder-flyer.tmpl.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false
      }).then(function () {

      }, function () {

      });
    }

    function popWhatsNewDialog() {
      $mdDialog.show({
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        multiple: true,
        templateUrl: 'app/common-modules/whats-new/whats-new-tmpl.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false
      }).then(function () {
        WhatsNewService.updateHasReadValue(true);
      }, function () {
        WhatsNewService.updateHasReadValue(false);
      });
    }

    function hideDialog() {
      $mdDialog.hide();
    }

    function cancelDialog() {
      $mdDialog.cancel();
    }

    function requestOTP() {
      Auth.requestOTP('EZYPAYMENT_OTP').then(function (response) {
        if(response.data.status == 'OTP_SENT') {
          showOtpPrompt();
        }
      },
      function(error) {
        toastr.error('OTP Request Failed', 'Error');
      });
    }

    function authenticateAndRedirect(otp) {
      Auth.authenticateWithOTP(otp, 'EZYPAYMENT_OTP').then(function (response) {
        $mdDialog.hide();
        redirectToEzypayment();
      },
      function(error) {
        toastr.error('Invalid OTP', 'AUTHENTICATION FAILED');
      });
    }

    // timer for otp resend
    function counter() {
      vm.stopped = $timeout(function () {
        vm.counterMilliSeconds -= 1000;
        if (vm.counterMilliSeconds) {
          var seconds = vm.counterMilliSeconds / 1000;
          vm.counterMinutes = Math.floor(seconds / 60);
          if(vm.counterMinutes > 0) {
            vm.counterSeconds = seconds - (vm.counterMinutes * 60);
          }
          else {
            vm.counterSeconds = seconds;
          }
          counter();
        }
        else {
          vm.counterSeconds = 0;
          vm.counterMinutes = 0;
          stopCounter();
        }
      }, 1000);
    };

    function stopCounter(){
      vm.isOtpValid = false;
      $timeout.cancel(vm.stopped);
    }

    function showOtpPrompt() {
      // clear otp
      vm.otp = undefined;
      vm.isOtpValid = true;
      vm.counterSeconds = 0;
      vm.counterMinutes = 0;
      vm.counterMilliSeconds = GLOBAL_CONSTANTS.OTP_EXPIRY_MINUTES * 60 * 1000;
      counter();
      // show prompt
      $mdDialog.show({
        templateUrl: 'app/core/common-templates/otp-templates/otp-capture.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function validateOTP() {
      if (!vm.otp) {
        toastr.error('Invalid OTP!', 'Please check the OTP entered');
        return;
      }

      if (vm.otp && (vm.otp.length < 5 || vm.otp.length > 8)) {
        toastr.error('Invalid OTP!', 'Please check the OTP entered');
        return;
      }
      authenticateAndRedirect(vm.otp);
    }

    function resendOTP() {
      requestOTP();
    }

    function getSupplierProfile() {
      SupplierAccountService.getSupplierProfile().
        then(function (response) {
          if (response) {
            vm.supplierProfile = response;
            vm.accessDTO = vm.supplierProfile.accessDTO;
            SupplierDataService.setSupplierProfile(vm.supplierProfile);
          }
        }, function (error) {
        });
      if (Principal.isCBEnabled()) {
        Auth.checkCbHealth().then(function () {
          SupplierAccountService.getCbSupplierProfile().
            then(function (response) {
              if (response) {
                SupplierDataService.setCbSupplierProfile(response);
              }
            }, function (error) {
            });
        });
      }
    }

    /**
     * function getEzysourcingTempToken gets temporary token for ezySourcing redirection where the temporary token contains the user details which has to be passed through the url
     */
    function getEzysourcingTempToken() {
      UserRedirectService.getEzysourcingTempToken(vm.userInfo.user.id).then
        (function (response) {
          vm.tempToken = response.id_token;
          UserRedirectService.redirectToEzysourcing(vm.tempToken);
        }, function (error) {
          NotificationService.error({
            title: 'ezysource',
            error: error
          });
        });
    }

    /**
     * redirection done in controller (to avoid broken UI instead of using ui-sref in html)
     */
    function redirectToEzypayment() {
      $state.go('ezypayment.supplier.dashboard', {
        reload: false
      });
    }


    function redirectToEzyap() {
      $state.go('ezyap.supplier.dashboard', {
        reload: false
      });
    }

    /**
      * @description redirect to ezyinventory
      */
    function redirectToEzyInventory() {
      var authToken = $localStorage.authenticationToken;
      window.open(API_CONSTANTS.ezyinventoryDomainName + "redirect?authtoken=" + authToken);
    }

    vm.getSupplierProfile();

    function back() {
      $window.history.back();
    }

    vm.logout = function () {
      if (Principal.isCBEnabled() && Principal.hasCbPlatform()) {
        Auth.checkCbHealth().then(function() {
          Auth.logoutBothPlatform()
          .then(function () {
            $window.location.href = API_CONSTANTS.cbBase + '#/autologin/delete/null/'
          });
        }).catch(function() {
          Auth.logout();
        });
      } else {
        Auth.logout();
      }
    };

    function hasCbPlatform() {
      return Auth.hasPermission("accessForEzypaymentCrossborder");
      // return Principal.hasCbPlatform() && Principal.isCBEnabled();
    }

    function checkPlan() {
      return true;
      //   return ((Principal.isUserHaveValidPlan() && Principal.isUserVerifiedKYC()) || !Principal.hasCbPlatform()) || !Principal.isCBEnabled();
    // 
    }

    vm.redirectToCbPlatform = function () {
      Auth.checkCbHealth().then(function() {
        var token = AuthServerProvider.getCbToken();
        var domToken = AuthServerProvider.getToken();
        if (token) {
          $window.location.href = API_CONSTANTS.cbBase + '#/autologin/save/' + token + '/' + domToken;
        } else {
          $window.location.href = API_CONSTANTS.cbBase;
        }
      }).catch(function() {
        toastr.error('Something went wrong');
      });
    }

    function redirectToAlexWorld() {
      $state.go('supplier.alex-world');
    }

    function submitRequest() {
        $mdDialog.show({
            templateUrl: 'app/core/common-templates/submit-request-hubspot.html',
            controller: function () {
                return vm;
              },
              controllerAs: 'vm',
              targetEvent: event,
            clickOutsideToClose: true
          });
    }

    vm.redirectToCbPayPlatform = function () {
        Auth.checkCbHealth().then(function() {
          UserRedirectService.sync().then(function () {
              var token = AuthServerProvider.getCbToken();
              var domToken = AuthServerProvider.getToken();
              if (token) {
                $window.location.href = API_CONSTANTS.cbBase + '#/autologin/save/' + token + '/' + domToken + '?destinationUrl=ezypayment/supplier/dashboard';
              } else {
                $window.location.href = API_CONSTANTS.cbBase;
              }
          }).catch(function (data) {
            toastr.error('Something went wrong');
          })
        }).catch(function() {
          toastr.error('Something went wrong');
        });
      }

      function showSubscriptionRenewalPopup(licenseExpirationDataString) {
        console.log(licenseExpirationDataString);
        $mdDialog.show({
          templateUrl: 'app/common-modules/web-reminder/web-reminder.html',
          controller: 'WebReminderController',
          controllerAs: 'vm',
          escapeToClose: false,
          clickOutsideToClose: false,
          resolve: {
            data: function () {
              return JSON.parse(licenseExpirationDataString);
            }
          }
        });
      }
  }
})();
