/*
 * @Author: Jayalekshmi 
 * @Date: 2020-01-17 10:35:31 
 * @File: showDecimal.filter.js
 * @Last Modified by: Lovin
 * @Last Modified time: 2020-07-13 13:08:27
 */

(function() {
    'use strict';

    angular
    .module('ezyprocureApp')
    .filter('showsDecimal', showsDecimal);

    function showsDecimal() {
      return showsDecimal;

      //show the number with decimal number w.r.t the number
      function showsDecimal(number){
     try {
      var fractionSize = 4;
      if(number % 1 != 0){
        fractionSize = String(number).split('.')[1].length;
        if(fractionSize == 1){
          fractionSize++;
        }
        
      }
      return number.toFixed(4);
     } catch (error) {
      
     }
      }
    }
  })();

